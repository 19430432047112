.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 85vh;
  padding: 100px 20px;
  background: url('../../assets/img/intro-desctop-bg.png') no-repeat center/cover;
  position: relative;

  @media screen and (max-height: 820px) {
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, rgba(#000, 1), rgba(#000, 0.7));
  backdrop-filter: blur(3px);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    padding: 20px 30px 5px;
    box-shadow: 0 0 15px 5px rgba(#000, 0.08);
    border-bottom: 3px solid #700c0b;
    background: #fff;
    position: relative;
    z-index: 2;
  }

  &_control {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    width: 100%;

    label {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      span {
        font-size: clamp(14px, 3vw, 16px);

        b {
          color: #e60501;
        }
      }
    }

    input {
      padding: 5px 10px;
      border: 1px solid #a8a8a8;
      outline: none;
      border-radius: 4px;
      height: 40px;
      font-size: clamp(16px, 3vw, 18px);
      width: 100%;

      &:focus {
        border-color: #000;
      }

      &:disabled {
        background: #d9d9d9;
        opacity: 0.8;
      }
    }

    &_otp {
      display: flex;
      justify-content: center;

      &_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        input {
          width: 40px;
          text-align: center;
        }
      }
    }

    &_icon {
      label {
        width: calc(100% - 5px - 40px);
      }
    }

    &_checkbox {
      label {
        flex-direction: row;
        align-items: center;
      }
      input {
        width: 15px;
      }
    }
  }
}

.icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #a8a8a8;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  margin-top: 24px;

  svg {
  }
}

.title {
  display: flex;
  flex-direction: column;
  text-align: center;

  h3 {
    font-size: clamp(20px, 5vw, 32px);
    text-transform: uppercase;
    font-weight: 700;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
    display: inline-block;
  }
}

.tabs {
  display: flex;
  justify-content: center;
}

.tab {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.submit {
}

.error {
  color: #e60501;
  font-size: clamp(10px, 3vw, 14px);

  &_otp {
    border-color: #e60501 !important;
  }
}

.success {
  color: #01e601;
  font-size: clamp(10px, 3vw, 14px);

  &_otp {
    border-color: #01e601 !important;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 5px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #ccc;

  &_item {
    p {
      color: rgba(#000, 0.6);
      font-weight: 400;
      font-size: 14px;
    }

    a {
      color: #700c0b;
      font-weight: 700;
      font-size: 15px;
    }
    &:last-child {
      margin-left: auto;

      a {
        color: #797979;
      }
    }
  }
}

.resend {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;

  p {
    &:last-child {
      color: #797979;
    }

    span {
      color: #700c0b;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
