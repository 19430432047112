.profile {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 120px 0 50px;
  height: auto;
  min-height: 700px;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    rgba(yellow, 0.1),
    rgba(blue, 0.1),
    rgba(green, 0.1),
    rgba(red, 0.1),
    rgba(orange, 0.1),
    rgba(yellow, 0.1)
  );
  animation: AnimateBG 20s ease infinite;

  transition: all 0.2s linear;

  @media screen and (max-width: 991px) {
    // padding: 100px 10px 50px;
  }

  @media screen and (max-width: 768px) {
    // min-height: 100dvh;
  }

  @media screen and (max-width: 450px) {
    // gap: 20px;
  }
}

.container {
  width: 100%;
  max-width: 700px;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 25px;
  background: rgba(#fff, 0.9);
  backdrop-filter: blur(4px);
  border: 2px solid rgba(#fff, 1);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(#000, 0.2);
  display: grid;
  grid-template-areas:
    'avatar bio'
    'subscriptions subscriptions';
  grid-template-columns: 150px 1fr;
  gap: 20px;

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'avatar'
      'bio'
      'subscriptions';
  }
}

.avatar {
  grid-area: avatar;
  display: flex;
  overflow: hidden;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #fff;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bio {
  grid-area: bio;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 4px;

  h4 {
    font-size: clamp(20px, 3vw, 24px);
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    line-height: 1;
  }

  span {
    color: rgba(#000, 0.6);
    line-height: 1;
  }

  p {
    line-height: 1;
    font-weight: 500;
  }
}

.created {
  span {
    color: #b00505;
  }
}

.subscriptions {
  grid-area: subscriptions;
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    margin-top: 10px;
  }
}

.getAccess {
  display: block;
  padding: 20px 35px;
  border-radius: 40px;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    rgba(yellow, 0.1),
    rgba(blue, 0.1),
    rgba(green, 0.1),
    rgba(red, 0.1),
    rgba(orange, 0.1),
    rgba(yellow, 0.1)
  );
  animation: AnimateBG 20s ease infinite;
  border: 2px solid rgba(#fff, 0.8);
  box-shadow: 0 0 10px rgba(#000, 0.2);
  transition: all 0.2s linear;
  width: fit-content;

  &:hover {
    background: rgba(green, 0.2);
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
