.prices {
  padding: 100px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1140px;
}

.title {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;

  h1 {
    font-size: 32px;
    color: rgba(#000, 0.9);

    span {
      color: #b70404;
    }
  }

  p {
    font-size: 18px;
    color: rgba(#000, 0.7);
    line-height: 1.4;
  }

  a {
    color: #000;

    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva,
    Verdana, sans-serif;

  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
