.about {
  padding: 80px 20px 70px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    135deg,
    rgba(#b00505, 1) 0%,
    rgba(#b00505, 1) 30%,
    rgba(#fff, 1) 30%,
    rgba(#fff, 1) 65%,
    rgba(#000, 1) 65%,
    rgba(#000, 1) 100%
  );

  @media screen and (max-width: 991px) {
    min-height: unset;
    padding: 100px 20px 50px;
  }

  &_content {
    width: 100%;
    max-width: 1300px;
    display: grid;
    grid-template-columns: 200px 1fr 180px;
    gap: 40px;

    @media screen and (max-width: 991px) {
      grid-template-columns: 140px 1fr 120px;
      padding-bottom: 200px;
    }

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
      padding-bottom: 0;
    }
  }

  &_title {
    text-align: center;

    h1 {
      font-size: 70px;

      @media screen and (max-width: 840px) {
        font-size: 45px;
      }
    }

    p {
      font-size: 20px;

      @media screen and (max-width: 840px) {
        font-size: 16px;
      }

      &:last-child {
        color: rgba(#000, 0.7);
        margin-top: 20px;
        font-size: 14px;
      }
    }
  }

  &_video {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    height: 100%;
    position: relative;

    @media screen and (max-width: 991px) {
      justify-content: flex-start;
    }

    &_wrapper {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;

      @media screen and (max-width: 991px) {
        width: 800px;
        position: absolute;
        bottom: -150px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
      }

      @media screen and (max-width: 840px) {
        width: 600px;
        bottom: -120px;
      }

      @media screen and (max-width: 700px) {
        position: initial;
        transform: translateX(0);
      }

      @media screen and (max-width: 700px) {
        width: 100%;
      }

      iframe {
        width: 100%;
        height: 380px;
        overflow: hidden;
        border-radius: 5px;
        border: 3px solid #fff;
        box-shadow: 0 0 10px rgba(#000, 0.4);

        @media screen and (max-width: 500px) {
          height: 280px;
        }

        @media screen and (max-width: 430px) {
          height: 200px;
        }
      }
    }
  }

  &_img {
    width: 100%;

    @media screen and (max-width: 700px) {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
      filter: drop-shadow(0 0 10px rgba(#fff, 1));
    }
  }
}

.gallery {
  padding: 80px 20px 50px;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    60deg,
    rgba(#b00505, 1) 0%,
    rgba(#000000, 1) 75%,
    rgba(#000, 1) 75%,
    rgba(#000, 1) 100%
  );

  &_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding: 0 40px;

    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }

    @media screen and (max-width: 450px) {
      padding: 0;
    }
  }

  &_title {
    color: #fff;
    margin-left: auto;

    h2 {
      font-size: 60px;
      line-height: 1;
    }

    p {
      font-size: 22px;
    }
  }

  &_items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
    width: 100%;
    padding: 5px 10px;

    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 450px) {
      padding: 0;
    }
  }

  &_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 0.9);
    width: 100%;
    cursor: pointer;
    box-shadow: 0 0 16px 7px rgba(#000, 0.6);

    &_img {
      width: 100%;
      height: 300px;

      @media screen and (max-width: 991px) {
        height: 250px;
      }

      @media screen and (max-width: 880px) {
        height: 200px;
      }

      @media screen and (max-width: 768px) {
        height: 300px;
      }

      @media screen and (max-width: 576px) {
        height: 400px;
      }

      @media screen and (max-width: 450px) {
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
        display: block;
      }
    }

    &_title {
      padding: 10px;
      background: #fff;
      width: 100%;

      h4 {
        font-size: 24px;
      }
    }
  }

  &_video {
    min-height: unset;
    background: linear-gradient(
      -45deg,
      rgba(#b00505, 1) 0%,
      rgba(#000000, 1) 75%,
      rgba(#000, 1) 75%,
      rgba(#000, 1) 100%
    );

    &_title {
      margin-left: 0;
    }

    &_items {
      grid-template-columns: repeat(3, 1fr);
      padding: 0;

      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &_item {
      &_img {
        position: relative;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          fill: #fff;
        }
      }
    }
  }
}

.support {
  padding: 50px;
  display: flex;
  justify-content: center;

  &_content {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  h2 {
    font-size: 30px;
    text-align: center;
  }

  p {
    font-size: 22px;
    text-align: center;
  }

  &_action {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 45px;
    border: 1px solid #f57d07;
    border-radius: 30px;
    font-size: 25px;
    text-transform: uppercase;
    text-decoration: none;
    color: #f57d07;
    transition: all 0.2s linear;
    cursor: pointer;
    background: #fff;

    &:hover {
      background: rgba(#f57d07, 0.1);
    }
  }
}

.video {
  &_wrapper {
    width: 95% !important;
    max-width: 950px;
    height: auto !important;
  }
}
