@import '../../../assets/scss/vars.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  width: 100%;
  height: 70px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background: rgba(#fff, 0.8);
  backdrop-filter: blur(4px);
  box-shadow: 0 0 15px rgba(#000, 0.3);
  border-bottom: 2px solid #b00505;

  @media screen and (max-width: 450px) {
    padding: 10px 20px;
  }
}

.nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.link {
  display: block;
  color: #000;
  text-decoration: none;
}

.gohome {
  margin-left: auto;
}
