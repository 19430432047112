.logo {
  font-size: 22px;
}

.dark {
  color: #000;
}

.light {
  color: #fff;
}

.link {
  color: inherit;
  text-decoration: none;
  font-size: inherit;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    display: block;
    width: 80px;
    height: 40px;
  }

  span {
    font-size: 15px;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }
}
