.contacts {
  width: 100%;
  padding: 150px 20px 100px;
  background: linear-gradient(
    180deg,
    rgb(250 250 250) 0%,
    rgba(245, 245, 245, 1) 50%,
    rgba(245, 245, 245, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 450px) {
    padding: 120px 10px 80px;
  }
}

.content {
  width: 100%;
  max-width: 950px;
  background: #fff;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 25px;
  }

  @media screen and (max-width: 450px) {
    padding: 10px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 10px;

  a {
    color: #b00505;
    font-weight: 700;
  }
}

.title {
  text-align: center;
  h2 {
    font-size: 28px;
    color: #2c2c2c;
    margin-bottom: 5px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}

.jur {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 20px 20px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  $self: &;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 550px) {
    gap: 15px;
    padding: 15px 10px;
  }

  &_wrapper {
    width: 100%;
    border: 1px solid #f5f5f5;

    @media screen and (max-width: 768px) {
      max-width: 500px;
      padding: 20px 10px;
    }

    @media screen and (max-width: 450px) {
      padding: 20px 0;
    }
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @media (max-width: 768px) {
      gap: 20px;
    }
  }

  &_footer {
    padding: 10px;

    a {
      color: #b00505;
      font-weight: 700;
    }
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7px;
    text-align: center;

    h3 {
      font-size: 28px;
      font-weight: 400;

      @media screen and (max-width: 768px) {
        font-weight: 700;
      }
    }

    p {
      font-size: 16px;
    }
  }

  &_control {
    width: 100%;
    position: relative;

    input,
    textarea {
      width: 100%;
      padding: 15px 20px 15px 10px;
      border: 1px solid transparent;
      border-radius: 4px;
      outline: none;
      background: #f2f2f2;
      font-size: 16px;

      &::placeholder {
        color: #a1a1a1;
      }

      &:focus {
        outline: none;
        border-color: rgba(#2e9fb3, 0.8);
      }
    }

    textarea {
      resize: none;
      height: 200px;
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
  &_w100 {
    width: 100%;
  }
}

.error {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 14px;
  color: #ff3131;
  font-style: italic;
}
