.card {
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 0 10px rgba(#000, 0.06);
  position: relative;

  @media screen and (max-width: 576px) {
    min-height: 250px;
  }
}

.poster {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 576px) {
      object-position: right top;
    }
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: linear-gradient(to top, rgba(#000, 1), rgba(#000, 0));
  padding: 40px 10px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  color: #fff;
}

.views {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  top: 10px;
  right: 10px;
}

.title {
  h3 {
    font-size: 22px;
  }
}

.play {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10;

  &_btn {
    width: 40px;
    height: 40px;
    background: none;
    border-radius: 50%;
    outline: none;
    border: none;
    box-shadow: 0 0 10px rgba(#000, 0.4);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    color: #fff;
    display: block;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 5px 10px;
    font-size: 12px;
  }

  &_link {
    font-size: 14px;
    color: #fff;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}

.countDown {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

  h5 {
    font-size: 16px;
  }

  p {
    &:first-of-type {
      display: block;
      font-size: 16px;
    }
  }
}
