.promocode {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;

  &_has {
    span {
      cursor: pointer;
    }
  }
}

.trigger {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  // max-width: 550px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.title {
  font-size: clamp(18px, 3vw, 22px);
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 450px) {
  }
}

.input {
  outline: none;
  border: 1px solid rgba(#000, 0.09);
  padding: 10px;
  border-radius: 5px;
  width: 100%;

  @media screen and (max-width: 450px) {
  }

  &::placeholder {
    color: rgba(#000, 0.4);
  }
}

.submit {
  width: 100% !important;
}
