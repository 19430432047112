.not_found {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  h1 {
    font-size: 80px;
    line-height: 1;
  }

  h3 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border: 1px solid #000;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
}
