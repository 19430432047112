.wrapper {
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(#000, 0.08), 0 2px 4px rgba(#000, 0.12);
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 991px) {
    padding: 10px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }

  &_control {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    label {
      span {
        font-size: 16px;
        color: rgba(#000, 0.7);
        font-weight: 600;
      }
    }

    input {
      width: 100%;
      padding: 3px 0;
      border: none;
      border-bottom: 1px solid rgba(#000, 0.2);
      outline: none;
      font-size: 18px;
    }
  }
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;

  @media screen and (max-width: 991px) {
    width: 50px;
    height: 50px;
  }

  &_preview {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_change {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
    cursor: pointer;

    svg {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      background: rgba(#fff, 0.4);

      svg {
        visibility: visible;
        opacity: 1;
      }
    }

    input {
      visibility: hidden;
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
}

.inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 991px) {
    width: fit-content;
    grid-template-columns: 1fr 1fr;
  }
}

.created {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media screen and (max-width: 991px) {
    width: fit-content;
  }

  &_title {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;

    @media screen and (max-width: 991px) {
      text-align: left;
    }
  }

  &_date {
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
      color: rgba(#000, 0.7);
      font-size: 14px;
    }
  }
}

.activated {
  font-size: 16px;
  font-weight: 600;
  color: #13e700;

  &_false {
    color: #ce0000;
  }
}
