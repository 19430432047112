.releases {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background: #fff;
  padding: 10px 10px 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  h4 {
    font-size: 16px;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 10px;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      gap: 3px;
    }

    li {
      padding: 5px 10px;
      border: 1px solid rgba(#000, 0.07);
      border-radius: 5px;
      font-size: 14px;
      text-align: center;

      a {
        text-decoration: underline;
        color: #bf0303;
      }

      span {
        font-weight: 700;
        color: #bf0303;
      }
    }
  }

  .notice {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    p {
      font-size: 13px;
      color: rgba(#000, 0.7);
    }
  }
}
