.socials {
  padding: 30px 0 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  list-style: none;

  li {
    position: relative;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;

      img {
        display: block;
        height: 100%;
      }
    }
  }
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  border: 1px solid rgba(#fff, 1);
  padding: 5px 10px;
  font-size: 12px;
  background: rgba(#000, 0.7);
  color: #fff;
  width: max-content;
  max-width: 220px;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
}
