.countdown {
  // background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;

  .notice,
  h3 {
    color: #fff;
  }

  .notice {
    font-size: 16px;
  }

  h3 {
    font-size: 20px;
  }

  &.dmode {
    h3,
    p.notice {
      color: #000;
    }
  }
}

.flipClock {
  display: flex;
  gap: 15px;
  padding: 30px 10px 10px 0;
}

.flipUnitContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60px;
  height: 56px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 10px 10px -10px grey;

  @media screen and (max-width: 576px) {
    width: 50px;
    height: 45px;
  }

  @media screen and (max-width: 450px) {
    // width: 60px;
    // height: 60px;
  }

  p {
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 10;
    color: #fff;
  }

  &.dmode {
    background-color: #000;
    p {
      color: #000;
    }
  }
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border: 1px solid whitesmoke;

  span {
    font-size: 2em;
    font-family: 'Droid Sans Mono', monospace;
    font-weight: lighter;
    color: #333333;

    @media screen and (max-width: 450px) {
      font-size: 1.4em;
    }
  }

  &.dmode {
    span {
      color: #fff;
    }
  }
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid whitesmoke;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  span {
    transform: translateY(50%);
  }
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid whitesmoke;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  span {
    transform: translateY(-50%);
  }
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;

  span {
    font-family: 'Droid Sans Mono', monospace;
    font-size: 2em;
    font-weight: lighter;
    color: #333333;

    @media screen and (max-width: 450px) {
      font-size: 1.4em;
    }
  }

  &.dmode {
    span {
      color: #fff;
    }
  }

  &.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg);
    background-color: white;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-top: 0.5px solid whitesmoke;

    span {
      transform: translateY(-50%);
    }

    &.dmode {
      background-color: #000;
      border: 0.5px solid rgb(20, 20, 20);
      border-top: 0.5px solid rgb(22, 22, 22);
    }
  }

  &.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    background-color: white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 0.5px solid whitesmoke;
    border-bottom: 0.5px solid whitesmoke;

    span {
      transform: translateY(50%);
    }

    &.dmode {
      background-color: #000;
      border: 0.5px solid rgb(20, 20, 20);
      border-top: 0.5px solid rgb(22, 22, 22);
    }
  }
}
.fold {
  animation: foldAnimation 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

.unfold {
  animation: unfoldnimation 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
  transform-style: preserve-3d;
}

@keyframes foldAnimation {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(-180deg);
  }
}
@keyframes unfoldnimation {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
