.tabs {
  display: flex;
  align-items: center;
  position: relative;
}

.tab {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: #b00505;
  background: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 5px solid #b00505;

  &.active {
    color: #fff;
    background: #b1b1b1;
    border-bottom-color: #fff;
  }
}
