.views {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: fit-content;
  padding: 0 8px;
  height: 30px;
  color: #000;
}
