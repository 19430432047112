.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  $self: &;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 550px) {
    gap: 15px;
    padding: 15px 10px;
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @media (max-width: 768px) {
      gap: 20px;
    }
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    text-align: center;

    h3 {
      font-size: 28px;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      font-size: 16px;
    }
  }

  &_control {
    width: 100%;
    position: relative;

    input {
      &:not([type='checkbox']) {
        width: 100%;
        padding: 15px 20px 15px 10px;
        border: 1px solid transparent;
        border-radius: 4px;
        outline: none;
        background: #f2f2f2;
        font-size: 16px;

        &::placeholder {
          color: #a1a1a1;
        }

        &:focus {
          outline: none;
          border-color: rgba(#2e9fb3, 0.8);
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
  &_w100 {
    width: 100%;
  }
}

.error {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 14px;
  color: #ff3131;
  font-style: italic;
}

.passwordIcon {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding: 0 10px 0 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
