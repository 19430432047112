*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Sofia Sans Condensed', sans-serif;

  &.show_modal {
    overflow-y: hidden;
  }
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  width: fit-content;
  padding: 10px 26px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  outline: none;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  transition: all 0.2s linear;
  border-radius: 2px;

  &:hover {
    box-shadow: 0 0 10px rgba(#000, 0.25);
  }

  &.prime {
    color: #fff;
    background: #bf0303;

    &:hover {
      color: #bf0303;
      background: #fff;
    }
  }

  &.second {
    color: #bf0303;
    background: #fff;

    &:hover {
      color: #000;
      background: rgba(#fff, 1);
    }
  }

  &.third {
    background: #bf0303;
    color: #fff;

    &:hover {
      background: darken(#bf0303, 10);
      border-color: #fff;
    }
  }

  &.subscribe {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.outlined {
    background: rgba(#000, 0.1);
    color: #fff;

    &:hover {
      background: rgba(#bf0303, 0.6);
      color: #fff;
    }
  }

  &.rounded {
    border-radius: 20px;
  }

  &.fw {
    width: 100%;
  }

  &:disabled {
    cursor: default;
    box-shadow: none;
    color: #000 !important;
    background: #888888 !important;
  }
}

.form {
  $self: &;

  &-auth {
  }
}
