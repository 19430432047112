.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.name {
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 5px 10px;
  background: #353535;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;

  &_rotate {
    transform: rotate(180deg);
  }
}

.actions {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 80%;
  right: 0;
  z-index: 15;
  transition: all 0.1s linear;
  padding-top: 5px;
  color: #fff;
  width: 200px;
  cursor: default;
  box-shadow: 0 0 10px rgba(#000, 0.08);

  &.show {
    opacity: 1;
    visibility: visible;
    top: 100%;
  }

  &_header {
    background: #353535;
    border-radius: 4px 4px 0 0;
    border-top: 3px solid #b00505;
    padding: 10px 15px;
    border-bottom: 1px solid lighten(#353535, 10);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: all 0.2s linear;

    h4 {
      font-size: 18px;
      color: #fff;
    }

    p {
      font-size: 14px;
      color: darken(#fff, 20);
    }

    &:hover {
      background: lighten(#353535, 5);
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: #353535;
    padding: 10px;
    border-bottom: 1px solid lighten(#353535, 10);

    .link {
      width: 100%;
      display: flex;
      align-items: center;
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      padding: 5px;
      transition: all 0.2s linear;
      border-radius: 4px;

      &:hover {
        background: rgba(#fff, 0.1);
      }
    }
  }

  &_footer {
    background: #353535;
    padding: 10px;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      background: #fff;
      color: #000;
      padding: 5px 10px;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
}
