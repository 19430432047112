.card {
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: center;
  padding: 120px 0 80px;
}

.content {
  width: 100%;
  max-width: 950px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;

  @media screen and (max-width: 576px) {
    padding: 0 5px;
  }
}

.goBack {
  color: rgba(#000, 0.7);
  text-decoration: none;
  display: flex;
  width: fit-content;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 5px 0 15px;

  @media screen and (max-width: 576px) {
    padding: 5px 10px 10px;
  }
}

.title {
  h4 {
    font-size: 22px;
  }
}
.description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 0 20px;

  h4 {
    font-size: clamp(18px, 3vw, 22px);
    text-transform: uppercase;
    font-weight: 600;
  }

  p {
    font-size: clamp(14px, 3vw, 16px);
  }
}
.body {
}
.footer {
  padding: 15px 0;

  @media screen and (max-width: 576px) {
    padding: 10px;
  }
}

.comments {
  h3 {
    font-size: 24px;
    font-weight: 700;
    color: rgba(#000, 0.8);

    @media screen and (max-width: 576px) {
      font-size: 18px;
    }
  }

  &_notice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;

    @media screen and (max-width: 576px) {
      padding: 20px 0;
    }
  }
}

.player {
}
