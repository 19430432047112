.agreement {
  padding: 100px 20px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 500px) {
    padding: 100px 10px;
  }
}

.content {
  width: 100%;
  max-width: 950px;
  border: 1px solid rgba(#000, 0.1);
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 500px) {
    padding: 20px 10px;
  }

  h4 {
    font-size: 30px;
    font-weight: 700;

    @media screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  h5 {
    font-size: 24px;
    font-weight: 500;

    @media screen and (max-width: 500px) {
      font-size: 20px;
    }
  }
}

.part {
  display: flex;
  flex-direction: column;
  gap: 10px;

  ul {
    list-style: none;

    li {
      margin-bottom: 5px;
    }
  }

  a {
    color: #000;
    font-weight: 600;
  }
}
