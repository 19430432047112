.footer {
  width: 100%;
  padding: 80px 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: auto;
  background: rgba(#000, 1);
  border-top: 1px solid darken(#b00505, 20);

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 800px) {
    gap: 10px;
    align-items: center;
    text-align: center;
  }
}

.center {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}

.right {
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  h4 {
    color: #fff;
    margin-top: 20px;
  }
}

.links {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    align-items: center;
  }

  &_item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    color: #fff;
    text-decoration: none;
    padding: 5px;
    transition: all 0.2s linear;

    // @media screen and (max-width: 576px) {
    //   width: 100%;
    //   border: none;
    //   border-bottom: 1px solid rgba(#fff, 0.5);
    //   padding: 10px 5px;
    //   flex-direction: row;
    // }

    span {
      letter-spacing: 1px;
    }

    &:hover {
      color: #db4040;
      text-decoration: underline;
      gap: 15px;
    }
  }

  &_privacy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    &_item {
      font-size: 14px;
      color: rgba(#fff, 0.5);
      text-decoration: underline;
    }
  }
}

.payment {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 550px;
  padding: 10px 0;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }

  &_variant {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40px;

    img {
      width: 100%;
    }
  }
}

.info {
  h4 {
    margin-bottom: 5px;
    color: #fff;
  }
  p {
    color: #ccc;
  }
}

.copy {
  color: #fff;
}
