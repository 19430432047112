.container {
  height: 600px;
  margin-top: auto;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
}
