.subs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.block {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid rgba(#000, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:nth-child(2n + 1) {
    background: rgba(#8ae8ff, 0.1);
  }

  &_title {
    font-size: 20px;
    font-weight: 600;
  }
}

.title {
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 10px;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.cancel {
  background: 0;
  outline: none;
  border: none;
  color: #b00505;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.timer {
  display: flex;
  justify-content: flex-start;
}
