@font-face {
  font-family: 'HaginCapsMedium-Medium';
  src: url(../../assets/fonts/HaginCapsMedium-Medium.eot);
  src: url(../../assets/fonts/HaginCapsMedium-Medium.eot?#iefix) format('embedded-opentype'),
    url(../../assets/fonts/HaginCapsMedium-Medium.woff) format('woff'),
    url(../../assets/fonts/HaginCapsMedium-Medium.woff2) format('woff2'),
    url(../../assets/fonts/HaginCapsMedium-Medium.ttf) format('truetype');
}

.promo {
  width: 100%;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  background: lighten(#000, 10);
  font-family: 'HaginCapsMedium-Medium';
}

.content {
  width: 100%;
  max-width: 1300px;
  background: #000;
  box-shadow: 0 0 10px rgba(#000, 0.7);
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  color: #fff;
  overflow: hidden;

  @media screen and (max-width: 1300px) {
    max-width: 900px;
  }

  @media screen and (max-width: 991px) {
    max-width: 550px;
  }
}

.block {
  width: 100%;
  min-height: 400px;
  height: auto;
  border-bottom: 1px solid #4d0202;

  @media screen and (max-width: 576px) {
    min-height: unset;
  }
}

.bg_logo {
  position: relative;
  width: 500px;

  @media screen and (max-width: 991px) {
    width: 300px;
  }

  @media screen and (max-width: 576px) {
    width: 150px;
  }

  &_sm {
    width: 200px;
  }

  &_adult,
  &_text {
    position: absolute;
    z-index: 2;
  }

  &_adult {
    right: -140px;
    top: 0;
    z-index: 2;
    padding: 20px;
    border-radius: 50%;
    background: radial-gradient(lighten(#4d0202, 20), #4d0202);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 40px;
    color: rgba(#fff, 1);

    @media screen and (max-width: 991px) {
      right: -100px;
    }

    @media screen and (max-width: 576px) {
      font-size: 24px;
      width: 40px;
      height: 40px;
      right: -50px;
    }

    &_sm {
      font-size: 24px;
      width: 40px;
      height: 40px;
      right: -70px;
    }
  }

  &_text {
    top: -20px;
    left: 0;
    z-index: 2;
    font-size: 36px;
    text-transform: uppercase;

    @media screen and (max-width: 991px) {
      font-size: 24px;
      top: -15px;
    }

    @media screen and (max-width: 576px) {
      font-size: 12px;
      top: -5px;
    }

    &_sm {
      font-size: 16px;
      top: -10px;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.logos {
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1300px) {
    width: 150px;
  }
}

.logo {
  width: 100%;

  img {
    display: block;
    width: 100%;
  }
}

.intro {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding-right: 40px;
  padding-top: 40px;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
    padding-right: 0;
    padding-top: 20px;
  }

  &_left,
  &_right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &_img {
      width: 100%;
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &_left {
    padding-top: 120px;

    @media screen and (max-width: 991px) {
      padding-top: 20px;
    }

    &_text {
      text-align: right;
      font-size: 42px;
      padding-right: 40px;
      text-transform: uppercase;

      @media screen and (max-width: 1300px) {
        font-size: 28px;
        padding-right: 35px;
      }
    }

    &_img {
    }
  }

  &_right {
    gap: 40px;

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      @media screen and (max-width: 991px) {
        padding: 0 40px;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
      }
    }

    &_text {
      font-size: 40px;

      @media screen and (max-width: 1300px) {
        font-size: 24px;
      }

      @media screen and (max-width: 576px) {
        text-align: center;
      }
    }
  }

  &_adult {
    position: absolute;
    right: 40px;
    bottom: 200px;
    z-index: 2;
    padding: 20px;
    border: 1px solid #fff;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 40px;
    color: rgba(#fff, 0.6);

    @media screen and (max-width: 1300px) {
      bottom: 100px;
    }
  }
}

.anotice {
  display: flex;
  justify-content: flex-start;
  position: relative;

  &_bg {
    position: absolute;
    right: 150px;
    top: 0;
    height: 100%;

    @media screen and (max-width: 576px) {
      right: 50%;
      transform: translateX(50%);
    }

    img {
      display: block;
      height: 100%;
    }
  }

  &_content {
    padding: 70px 0 70px 100px;
    position: relative;
    z-index: 3;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 991px) {
      padding: 60px 0 40px 30px;
    }
  }

  &_title {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h4 {
      font-size: 30px;

      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }
  }

  &_text {
    margin-top: 40px;
    p {
      font-family: 'Sofia Sans Condensed', sans-serif;
      letter-spacing: 0.2px;
      font-size: 20px;
      line-height: 1.4;
    }
  }
}

.anotice2 {
  padding: 100px 120px 220px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    padding: 80px 70px 180px;
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 991px) {
    padding: 50px 20px 0;
  }

  &_bg {
    position: relative;
  }

  &_img {
    position: absolute;
    top: 50%;
    left: -40px;
    height: 400px;

    @media screen and (max-width: 991px) {
      position: relative;
      top: 0;
      left: 0;
      z-index: 5;
      margin-top: -40px;
      height: unset;
      width: 100%;
    }
    img {
      display: block;
      height: 100%;

      @media screen and (max-width: 991px) {
        height: auto;
        width: 100%;
      }
    }
  }

  &_content {
    position: relative;
    z-index: 4;

    @media screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  &_text {
    padding-bottom: 100px;
    max-width: 350px;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 1300px) {
      max-width: unset;
      text-align: center;
    }
    p {
      font-family: 'Sofia Sans Condensed', sans-serif;
      letter-spacing: 0.1px;
      font-size: 24px;
      line-height: 1.4;
    }
  }
}

.look_a_like {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    width: 100%;
  }
}

.character {
  padding: 100px 120px 40px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 991px) {
    padding: 100px 20px 40px;
  }

  &_img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    height: 500px;

    img {
      display: block;
      height: 100%;
    }
  }

  &_top,
  &_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 4;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }

  &_bottom {
    padding: 100px 0;
  }

  &_voice {
    h4 {
      font-size: 30px;
    }

    p {
    }
  }

  &_name {
    h3 {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    p {
      color: #d32e2e;
      font-size: 18px;
      font-family: 'Sofia Sans Condensed', sans-serif;
      font-weight: 600;
    }
  }
}

.about {
  display: flex;
  justify-content: space-between;
  position: relative;

  &_content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &_text {
    padding: 70px 0 70px 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 1300px) {
      padding: 70px 0 70px 20px;
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      position: relative;
      z-index: 3;
      padding: 70px 0;
    }
  }

  &_img {
    // width: 350px;

    img {
      display: block;
      width: 100%;
    }
  }

  &_name {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 250px;

    h3 {
      font-size: 40px;
      text-transform: uppercase;
      line-height: 1;
    }

    p {
      font-size: 20px;
      color: #d32e2e;
    }
  }

  &_description {
    grid-column-start: 1;
    grid-column-end: 3;

    @media screen and (max-width: 991px) {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }

  &_movies {
    display: flex;
    gap: 15px;
    overflow-y: clip;
    max-height: 650px;

    @media screen and (max-width: 991px) {
      opacity: 0.3;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }

  &_column {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &_item {
      width: auto;
      height: 200px;

      img {
        display: block;
        width: auto;
        height: 100%;
      }
    }

    &:nth-child(2) {
      margin-top: -100px;
    }
  }
}
