.watch {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 120px 25px 80px;
  }
}

.goProfile {
  display: flex;

  a {
  }
}

.noAccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;

  h4 {
    font-size: clamp(20px, 3vw, 24px);
    text-transform: uppercase;
    font-weight: 700;
  }
}

.getAccess {
  display: block;
  padding: 20px 35px;
  border-radius: 40px;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    rgba(yellow, 0.1),
    rgba(blue, 0.1),
    rgba(green, 0.1),
    rgba(red, 0.1),
    rgba(orange, 0.1),
    rgba(yellow, 0.1)
  );
  animation: AnimateBG 20s ease infinite;
  border: 2px solid rgba(#fff, 0.8);
  box-shadow: 0 0 10px rgba(#000, 0.2);
  transition: all 0.2s linear;

  &:hover {
    background: rgba(green, 0.2);
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hasAccess {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  h4 {
    font-size: clamp(20px, 3vw, 24px);
    text-transform: uppercase;
    font-weight: 700;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1600px) {
    gap: 10px;
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
